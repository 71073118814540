@import url("https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* Colors */
:root {
  --btn-blue: hsl(226, 76%, 42%);
  --btn-blue__active: hsl(226, 70%, 52%);
  --btn-red: #d72627;
  --red: #ad1e1f;
  --nav-link: #2c2f30;
  --text-light: rgb(145, 145, 145);
  --footer-link-hover: rgb(208, 208, 208);
  --cherry-red: #d80001;
  --dark: #232323;
  --background-light: #faf9f6;
  --background-nav: #ebe7dd;
  --background-blue: hsl(226, 73%, 45%);
  --background-footer: hsl(226, 84%, 22%);

  --font-size--2: clamp(0.6944rem, 0.6576rem + 0.1837vw, 0.8rem);
  --font-size--1: clamp(0.8331rem, 0.7751rem + 0.2902vw, 1rem);
  --font-size-0: clamp(1rem, 0.913rem + 0.4348vw, 1.25rem);
  --font-size-1: clamp(1.2rem, 1.0739rem + 0.6304vw, 1.5625rem);
  --font-size-2: clamp(1.44rem, 1.2615rem + 0.8924vw, 1.9531rem);
  --font-size-3: clamp(1.7281rem, 1.4801rem + 1.2402vw, 2.4413rem);
  --font-size-4: clamp(2.0738rem, 1.7335rem + 1.7011vw, 3.0519rem);
  --font-size-5: clamp(2.4881rem, 2.0266rem + 2.3076vw, 3.815rem);

  --container-padding: clamp(2.5em, 8vw, 8em);
  --section-padding: clamp(5em, 21vh, 12em);
  --gap-padding: clamp(1.5em, 4vw, 2.5em);
  --title-padding: clamp(5em, 9vh, 12em);
  --vh: 1em;

  --animation-primary: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

/* ------------------------- Hide Scrollbar -------------------------------------------------- */

/* Chrome, Safari, Opera */
body::-webkit-scrollbar {
  display: none;
}

/* IE & Edge */
body {
  -ms-overflow-style: none;
}

/* Firefox */
html {
  scrollbar-width: none;
  overflow-x: hidden;
}

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

main {
  position: relative;
  background-color: var(--background-blue);
}

::selection {
  background-color: var(--cherry-red);
  color: var(--background-light);
  text-shadow: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--nav-link);
}

a[href^="tel"] {
  text-decoration: inherit;
  color: inherit;
}

button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
}

h1 {
  font-size: clamp(6rem, 25vw + 1rem, 6rem);
}

h2 {
  font-size: var(--font-size-5);
}

h3 {
  font-size: var(--font-size-4);
}

h4 {
  font-size: var(--font-size-3);
}

h5 {
  font-size: var(--font-size-2);
  font-weight: 500;
}

h6 {
  font-size: var(--font-size-1);
}

p {
  font-size: var(--font-size-0);
}

.container {
  width: 90%;
  margin-inline: auto;
}

.container__medium {
  width: 70%;
  margin-inline: auto;
}

.container__small {
  width: 55%;
  margin-inline: auto;
}

.toast {
  top: 1rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.flex-col {
  display: block;
  width: 100%;
  order: 2;
  position: relative;
}

img {
  width: 100%;
}

.btn-primary {
  padding: 0.5em 1em;
  width: max-content;
  background-color: var(--btn-blue);
  color: white;
  font-weight: 700;
  transition: all 0.3s ease;
  border-radius: 0.375rem;
}

.btn-primary:hover {
  background-color: var(--btn-blue__active);
}

.btn-secondary {
  padding: 0.5em 1em;
  width: max-content;
  background-color: var(--btn-red);
  color: white;
  font-weight: 700;
  transition: all 0.3s ease;
  border-radius: 0.375rem;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: var(--red);
}

.center {
  text-align: center;
}

.copyright {
  padding-block: var(--gap-padding);
  color: #cfcfcf;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}
