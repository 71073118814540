@import "https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap";
:root {
  --btn-blue: #1a40bc;
  --btn-blue__active: #2f57da;
  --btn-red: #d72627;
  --red: #ad1e1f;
  --nav-link: #2c2f30;
  --text-light: #919191;
  --footer-link-hover: #d0d0d0;
  --cherry-red: #d80001;
  --dark: #232323;
  --background-light: #faf9f6;
  --background-nav: #ebe7dd;
  --background-blue: #1f46c7;
  --background-footer: #091f67;
  --font-size--2: clamp(.6944rem, .6576rem + .1837vw, .8rem);
  --font-size--1: clamp(.8331rem, .7751rem + .2902vw, 1rem);
  --font-size-0: clamp(1rem, .913rem + .4348vw, 1.25rem);
  --font-size-1: clamp(1.2rem, 1.0739rem + .6304vw, 1.5625rem);
  --font-size-2: clamp(1.44rem, 1.2615rem + .8924vw, 1.9531rem);
  --font-size-3: clamp(1.7281rem, 1.4801rem + 1.2402vw, 2.4413rem);
  --font-size-4: clamp(2.0738rem, 1.7335rem + 1.7011vw, 3.0519rem);
  --font-size-5: clamp(2.4881rem, 2.0266rem + 2.3076vw, 3.815rem);
  --container-padding: clamp(2.5em, 8vw, 8em);
  --section-padding: clamp(5em, 21vh, 12em);
  --gap-padding: clamp(1.5em, 4vw, 2.5em);
  --title-padding: clamp(5em, 9vh, 12em);
  --vh: 1em;
  --animation-primary: all .4s cubic-bezier(.7, 0, .3, 1);
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
}

html {
  scrollbar-width: none;
  overflow-x: hidden;
}

body {
  font-family: Poppins, sans-serif;
  overflow-x: hidden;
}

main {
  background-color: var(--background-blue);
  position: relative;
}

::selection {
  background-color: var(--cherry-red);
  color: var(--background-light);
  text-shadow: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: var(--nav-link);
  text-decoration: none;
}

a[href^="tel"] {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  color: inherit;
}

button {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1;
}

h1 {
  font-size: clamp(6rem, 25vw + 1rem, 6rem);
}

h2 {
  font-size: var(--font-size-5);
}

h3 {
  font-size: var(--font-size-4);
}

h4 {
  font-size: var(--font-size-3);
}

h5 {
  font-size: var(--font-size-2);
  font-weight: 500;
}

h6 {
  font-size: var(--font-size-1);
}

p {
  font-size: var(--font-size-0);
}

.container {
  width: 90%;
  margin-inline: auto;
}

.container__medium {
  width: 70%;
  margin-inline: auto;
}

.container__small {
  width: 55%;
  margin-inline: auto;
}

.toast {
  top: 1rem;
}

.row {
  flex-wrap: wrap;
  display: flex;
  position: relative;
}

.flex-col {
  order: 2;
  width: 100%;
  display: block;
  position: relative;
}

img {
  width: 100%;
}

.btn-primary {
  background-color: var(--btn-blue);
  color: #fff;
  border-radius: .375rem;
  width: max-content;
  padding: .5em 1em;
  font-weight: 700;
  transition: all .3s;
}

.btn-primary:hover {
  background-color: var(--btn-blue__active);
}

.btn-secondary {
  background-color: var(--btn-red);
  color: #fff;
  cursor: pointer;
  border-radius: .375rem;
  width: max-content;
  padding: .5em 1em;
  font-weight: 700;
  transition: all .3s;
}

.btn-secondary:hover {
  background-color: var(--red);
}

.center {
  text-align: center;
}

.copyright {
  padding-block: var(--gap-padding);
  color: #cfcfcf;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}
/*# sourceMappingURL=index.05ca120d.css.map */
